<template>
  <div>
    <div class="baseArea" v-if="toolbar=='light'">
      <div style="display:flex;align-items:center;justify-content:space-between">
        <label for="lightEnabled" class="m-0">亮度</label>
        <div class="custom-control custom-switch" style="display:inline-block">
          <input type="checkbox" class="custom-control-input" id="lightEnabled" @change="changePostprocessing" v-model="postprocessing.light.enabled">
          <label class="custom-control-label" for="lightEnabled"></label>
        </div>
      </div>
      <div v-if="postprocessing.light.enabled" style="display:flex;">
        <div style="flex:auto">
          <div class="ppitem" title="曝光设置将影响整体图像。">
            <label>曝光</label>
            <NumberButton class="nb" v-model="postprocessing.light.exposure" @change="changePostprocessing" :defaultValue="0.0" :wheelRatio="0.01" :moveRatio="0.05" :step="0.1" name=""></NumberButton>
          </div>
          <div class="ppitem" title="亮部设置仅影响图像的较亮部分。">
            <label>亮部</label>
            <NumberButton class="nb" v-model="postprocessing.light.lights" @change="changePostprocessing" :defaultValue="0.0" :wheelRatio="0.01" :moveRatio="0.05" :step="0.1" name=""></NumberButton>
          </div>
          <div class="ppitem" title="暗部设置仅影响图像的较暗部分。">
            <label>暗部</label>
            <NumberButton class="nb" v-model="postprocessing.light.shadows" @change="changePostprocessing" :defaultValue="0.0" :wheelRatio="0.01" :moveRatio="0.05" step="0.1" name=""></NumberButton>
          </div>
        </div>
        <div style="flex:auto">
          <div class="ppitem" title="用于亮部和暗部的像素范围会改变。
较大的值可使亮部和暗部变化之间的过渡更平滑。
设置越大，可能需要更大的质量设置以避免噪点。">
            <label>范围</label>
            <NumberButton class="nb" v-model="postprocessing.light.filterrange" @change="changePostprocessing" :defaultValue="60.0" :wheelRatio="0.1" :moveRatio="0.5" step="0.1" :min="0" :max="1000" name=""></NumberButton>
          </div>
          <div class="ppitem" title="亮部/暗部遮罩范围选择。
值越大，亮部/暗部分离越强，值越小，混合效果越好。">
            <label>遮罩</label>
            <NumberButton class="nb" v-model="postprocessing.light.masking" @change="changePostprocessing" :defaultValue="1.0" :wheelRatio="0.01" :moveRatio="0.01" step="0.1" :min="0.1" :max="10" name=""></NumberButton>
          </div>
          <div class="ppitem" title="质量/性能控制。
仅影响灯光和阴影设置。
质量设置值越低，图像处理速度越快，因此，最终图像中也会有更多的噪点。
值范围：0（最低质量）到 50（最高质量）。
在移动设备和平板电脑设备上，默认值为 3，以实现更快的渲染性能。
注意-更改质量设置可能是一项昂贵的操作（取决于浏览器和 GPU），因此在动画过程中不应更改此设置。">
            <label>质量</label>
            <NumberButton class="nb" v-model="postprocessing.light.quality" @change="changePostprocessing" :defaultValue="3" :wheelRatio="0.01" :moveRatio="0.05" step="1" :min="0" :max="50" name=""></NumberButton>
          </div>
        </div>
      </div>
    </div>
    <div class="baseArea" v-if="toolbar=='sharpen'">
      <div style="display:flex;align-items:center;justify-content:space-between">
        <label for="sharpenEnabled" class="m-0">锐化</label>
        <div class="custom-control custom-switch" style="display:inline-block">
          <input type="checkbox" class="custom-control-input" id="sharpenEnabled" @change="changePostprocessing" v-model="postprocessing.sharpen.enabled">
          <label class="custom-control-label" for="sharpenEnabled"></label>
        </div>
      </div>
      <template v-if="postprocessing.sharpen.enabled">
        <div class="ppitem" title="锐化/对比度增强的强度。">
          <label>强度</label>
          <NumberButton class="nb" v-model="postprocessing.sharpen.strength" @change="changePostprocessing" :defaultValue="0.0" :wheelRatio="0.01" :moveRatio="0.05" step="0.1" :min="-1" :max="100" name=""></NumberButton>
        </div>
        <div class="ppitem" title="锐化范围（以像素为单位）。">
          <label>范围</label>
          <NumberButton class="nb" v-model="postprocessing.sharpen.range" @change="changePostprocessing" :defaultValue="1.5" :wheelRatio="0.1" :moveRatio="0.5" step="0.1" :min="0" :max="1000" name="范围"></NumberButton>
        </div>
        <div class="ppitem" title="质量设置会影响模糊质量和渲染性能。
质量设置值越低，图像处理速度越快，因此，最终图像中也会有更多的噪点。
值范围：1（最低质量）到 50（最高质量）。 通常，对于大多数使用情况，在 5 到 10 之间设置就足够了。
对于小范围设置，甚至较低的质量设置（例如 1-3）也足够好。
另外，设置质量=1 是在非常低的范围设置（例如低于 2）下获得良好性能/质量比的特殊情况。
注意-更改质量设置可能是一项昂贵的操作（取决于浏览器和 GPU），因此在动画过程中不应更改此设置。">
          <label>质量</label>
          <NumberButton class="nb" v-model="postprocessing.sharpen.quality" @change="changePostprocessing" :defaultValue="5" :wheelRatio="0.01" :moveRatio="0.05" step="1" :min="1" :max="50" name=""></NumberButton>
        </div>
        <div title="仅在亮度通道而不是 RGB 通道中进行锐化。
启用后，处理会稍微快一些（内部仅 2 次而不是 3 次）。
对于正常锐化，最好不要使用什么设置，但是对于局部对比度增强，禁用此设置时可能看起来更好。" class="custom-control custom-switch switch ppitem">
          <input type="checkbox" class="custom-control-input" id="sharpenLuminanceonly" @change="changePostprocessing" v-model.trim="postprocessing.sharpen.luminanceonly">
          <label class="custom-control-label" for="sharpenLuminanceonly">仅亮度通道</label>
        </div>
      </template>
    </div>
    <div class="baseArea" v-if="toolbar=='blur'">
      <div style="display:flex;align-items:center;justify-content:space-between">
        <label for="blurEnabled" class="m-0">模糊</label>
        <div class="custom-control custom-switch" style="display:inline-block">
          <input type="checkbox" class="custom-control-input" id="blurEnabled" @change="changePostprocessing" v-model="postprocessing.blur.enabled">
          <label class="custom-control-label" for="blurEnabled"></label>
        </div>
      </div>
      <template v-if="postprocessing.blur.enabled">
        <div class="ppitem" title="模糊范围（以像素为单位）。">
          <label>范围</label>
          <NumberButton class="nb" v-model="postprocessing.blur.range" @change="changePostprocessing" :defaultValue="0.0" :wheelRatio="0.1" :moveRatio="0.5" step="0.1" :min="0" :max="1000" name=""></NumberButton>
        </div>
        <div class="ppitem" title="质量设置会影响模糊质量和渲染性能。
质量设置值越低，图像处理速度越快，因此，最终图像中也会有更多的噪点。
值范围：1（最低质量）到 50（最高质量）。 通常，对于大多数使用情况，在 5 到 10 之间设置就足够了。
在移动设备和平板电脑设备上，默认值为 5，以提高渲染性能。
注意-更改质量设置可能是一项昂贵的操作（取决于浏览器和 GPU），因此在动画过程中不应更改此设置。">
          <label>质量</label>
          <NumberButton class="nb" v-model="postprocessing.blur.quality" @change="changePostprocessing" :defaultValue="5" :wheelRatio="0.01" :moveRatio="0.05" step="1" :min="1" :max="50" name=""></NumberButton>
        </div>
        <div title="在线性 RGB 空间中进行模糊处理。
这样可以产生更正确的混合/模糊颜色，但同时也会降低性能，因此默认情况下将其禁用。" class="custom-control custom-switch switch ppitem">
          <input type="checkbox" class="custom-control-input" id="blurLinearrgb" @change="changePostprocessing" v-model="postprocessing.blur.linearrgb">
          <label class="custom-control-label" for="blurLinearrgb">线性 RGB</label>
        </div>
        <div title="默认情况下，模糊图像时边缘的像素会重复。
启用此设置后，像素将被镜像。
这样可以稍微提高图像质量并减少边缘的伪影，但同时也要降低性能，因此默认情况下将其禁用。" class="custom-control custom-switch switch ppitem">
          <input type="checkbox" class="custom-control-input" id="blurMirroredges" @change="changePostprocessing" v-model="postprocessing.blur.mirroredges">
          <label class="custom-control-label" for="blurMirroredges">镜像边缘</label>
        </div>
      </template>
    </div>
    <div class="EditToolbar">
      <div class="space"></div>
      <button class="btn toorbarBtn back" style="" @click="$emit('back')">
        <div><i class="fas fa-chevron-left"></i></div>
      </button>
      <button v-if="toolbar=='light'" class="btn toorbarBtn active" @click="toolbar=null">
        <div><i class="fas fa-sun"></i></div>
        <div>亮度</div>
      </button>
      <button v-else class="btn toorbarBtn" @click="toolbar='light'">
        <div><i class="fas fa-sun"></i></div>
        <div>亮度</div>
      </button>
      <button v-if="toolbar=='sharpen'" class="btn toorbarBtn active" @click="toolbar=null">
        <div>
          <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
            <path stroke="#fff" id="svg_4" d="m8.02076,2.77831c0,0 -5.27074,10.45848 -5.27075,10.43772c0.00001,0.02076 10.49999,-0.02074 10.49998,-0.0415c0.00001,0.02076 -5.22923,-10.39622 -5.22923,-10.39622z" opacity="1" fill="#fff" />
          </svg>
        </div>
        <div>锐化</div>
      </button>
      <button v-else class="btn toorbarBtn" @click="toolbar='sharpen'">
        <div>
          <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
            <path stroke="#fff" id="svg_4" d="m8.02076,2.77831c0,0 -5.27074,10.45848 -5.27075,10.43772c0.00001,0.02076 10.49999,-0.02074 10.49998,-0.0415c0.00001,0.02076 -5.22923,-10.39622 -5.22923,-10.39622z" opacity="1" fill="#fff" />
          </svg>
        </div>
        <div>锐化</div>
      </button>
      <button v-if="toolbar=='blur'" class="btn toorbarBtn active" @click="toolbar=null">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg"
               x="0px" y="0px"
               width="16" height="16"
               viewBox="0 0 172 172"
               style=" fill:#fff;">
            <path d="M138.0128,99.76c0,32.2672 -23.3232,58.48 -52.0128,58.48c-28.6896,0 -52.0128,-26.2128 -52.0128,-58.48c0,-32.2672 47.472,-82.56 49.4672,-84.8992c1.41619,-1.38083 3.67501,-1.38083 5.0912,0c1.9952,2.1328 49.4672,53.5264 49.4672,84.8992z"></path>
          </svg>
        </div>
        <div>模糊</div>
      </button>
      <button v-else class="btn toorbarBtn" @click="toolbar='blur'">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg"
               x="0px" y="0px"
               width="16" height="16"
               viewBox="0 0 172 172"
               style=" fill:#fff;">
            <path d="M138.0128,99.76c0,32.2672 -23.3232,58.48 -52.0128,58.48c-28.6896,0 -52.0128,-26.2128 -52.0128,-58.48c0,-32.2672 47.472,-82.56 49.4672,-84.8992c1.41619,-1.38083 3.67501,-1.38083 5.0912,0c1.9952,2.1328 49.4672,53.5264 49.4672,84.8992z"></path>
          </svg>
        </div>
        <div>模糊</div>
      </button>
      <button class="btn toorbarBtn" @click="postprocessing=newPostprocessing();changePostprocessing()">
        <div><i class="fas fa-reply"></i></div>
        <div>重置</div>
      </button>
      <div class="space"></div>
    </div>
  </div>
  <!--<div class="postprocessing" :class="{hide:postprocessingHide}">
      <div v-if="postprocessing" style="">
        <div>
          <div style="display:flex;align-items:center;justify-content:space-between">
            <label for="lightEnabled" class="m-0">亮度</label>
            <div class="custom-control custom-switch" style="display:inline-block">
              <input type="checkbox" class="custom-control-input" id="lightEnabled" @change="changePostprocessing" v-model="postprocessing.light.enabled">
              <label class="custom-control-label" for="lightEnabled"></label>
            </div>
          </div>
          <template v-if="postprocessing.light.enabled">
            <div class="ppitem" title="曝光设置将影响整体图像。">
              <NumberButton v-model="postprocessing.light.exposure" @change="changePostprocessing" :defaultValue="0.0" :wheelRatio="0.01" :moveRatio="0.05" :step="0.1" name="曝光"></NumberButton>
            </div>
            <div class="ppitem" title="亮部设置仅影响图像的较亮部分。">
              <NumberButton v-model="postprocessing.light.lights" @change="changePostprocessing" :defaultValue="0.0" :wheelRatio="0.01" :moveRatio="0.05" :step="0.1" name="亮部"></NumberButton>
            </div>
            <div class="ppitem" title="暗部设置仅影响图像的较暗部分。">
              <NumberButton v-model="postprocessing.light.shadows" @change="changePostprocessing" :defaultValue="0.0" :wheelRatio="0.01" :moveRatio="0.05" step="0.1" name="暗部"></NumberButton>
            </div>
            <div class="ppitem" title="用于亮部和暗部的像素范围会改变。
  较大的值可使亮部和暗部变化之间的过渡更平滑。
  设置越大，可能需要更大的质量设置以避免噪点。">
              <NumberButton v-model="postprocessing.light.filterrange" @change="changePostprocessing" :defaultValue="60.0" :wheelRatio="0.1" :moveRatio="0.5" step="0.1" :min="0" :max="1000" name="范围"></NumberButton>
            </div>
            <div class="ppitem" title="亮部/暗部遮罩范围选择。
  值越大，亮部/暗部分离越强，值越小，混合效果越好。">
              <NumberButton v-model="postprocessing.light.masking" @change="changePostprocessing" :defaultValue="1.0" :wheelRatio="0.01" :moveRatio="0.01" step="0.1" :min="0.1" :max="10" name="遮罩"></NumberButton>
            </div>
            <div class="ppitem" title="质量/性能控制。
  仅影响灯光和阴影设置。
  质量设置值越低，图像处理速度越快，因此，最终图像中也会有更多的噪点。
  值范围：0（最低质量）到 50（最高质量）。
  在移动设备和平板电脑设备上，默认值为 3，以实现更快的渲染性能。
  注意-更改质量设置可能是一项昂贵的操作（取决于浏览器和 GPU），因此在动画过程中不应更改此设置。">
              <NumberButton v-model="postprocessing.light.quality" @change="changePostprocessing" :defaultValue="3" :wheelRatio="0.01" :moveRatio="0.05" step="1" :min="0" :max="50" name="质量"></NumberButton>
            </div>
          </template>
        </div>
        <div>
          <div style="display:flex;align-items:center;justify-content:space-between">
            <label for="sharpenEnabled" class="m-0">锐化</label>
            <div class="custom-control custom-switch" style="display:inline-block">
              <input type="checkbox" class="custom-control-input" id="sharpenEnabled" @change="changePostprocessing" v-model="postprocessing.sharpen.enabled">
              <label class="custom-control-label" for="sharpenEnabled"></label>
            </div>
          </div>
          <template v-if="postprocessing.sharpen.enabled">
            <div class="ppitem" title="锐化/对比度增强的强度。">
              <NumberButton v-model="postprocessing.sharpen.strength" @change="changePostprocessing" :defaultValue="0.0" :wheelRatio="0.01" :moveRatio="0.05" step="0.1" :min="-1" :max="100" name="强度"></NumberButton>
            </div>
            <div class="ppitem" title="锐化范围（以像素为单位）。">
              <NumberButton v-model="postprocessing.sharpen.range" @change="changePostprocessing" :defaultValue="1.5" :wheelRatio="0.1" :moveRatio="0.5" step="0.1" :min="0" :max="1000" name="范围"></NumberButton>
            </div>
            <div class="ppitem" title="质量设置会影响模糊质量和渲染性能。
  质量设置值越低，图像处理速度越快，因此，最终图像中也会有更多的噪点。
  值范围：1（最低质量）到 50（最高质量）。 通常，对于大多数使用情况，在 5 到 10 之间设置就足够了。
  对于小范围设置，甚至较低的质量设置（例如 1-3）也足够好。
  另外，设置质量=1 是在非常低的范围设置（例如低于 2）下获得良好性能/质量比的特殊情况。
  注意-更改质量设置可能是一项昂贵的操作（取决于浏览器和 GPU），因此在动画过程中不应更改此设置。">
              <NumberButton v-model="postprocessing.sharpen.quality" @change="changePostprocessing" :defaultValue="5" :wheelRatio="0.01" :moveRatio="0.05" step="1" :min="1" :max="50" name="质量"></NumberButton>
            </div>
            <div title="仅在亮度通道而不是 RGB 通道中进行锐化。
  启用后，处理会稍微快一些（内部仅 2 次而不是 3 次）。
  对于正常锐化，最好不要使用什么设置，但是对于局部对比度增强，禁用此设置时可能看起来更好。" class="custom-control custom-switch switch ppitem">
              <input type="checkbox" class="custom-control-input" id="sharpenLuminanceonly" @change="changePostprocessing" v-model.trim="postprocessing.sharpen.luminanceonly">
              <label class="custom-control-label" for="sharpenLuminanceonly">仅亮度通道</label>
            </div>
          </template>
        </div>
        <div>
          <div style="display:flex;align-items:center;justify-content:space-between">
            <label for="blurEnabled" class="m-0">模糊</label>
            <div class="custom-control custom-switch" style="display:inline-block">
              <input type="checkbox" class="custom-control-input" id="blurEnabled" @change="changePostprocessing" v-model="postprocessing.blur.enabled">
              <label class="custom-control-label" for="blurEnabled"></label>
            </div>
          </div>
          <template v-if="postprocessing.blur.enabled">
            <div class="ppitem" title="模糊范围（以像素为单位）。">
              <NumberButton v-model="postprocessing.blur.range" @change="changePostprocessing" :defaultValue="0.0" :wheelRatio="0.1" :moveRatio="0.5" step="0.1" :min="0" :max="1000" name="范围"></NumberButton>
            </div>
            <div class="ppitem" title="质量设置会影响模糊质量和渲染性能。
  质量设置值越低，图像处理速度越快，因此，最终图像中也会有更多的噪点。
  值范围：1（最低质量）到 50（最高质量）。 通常，对于大多数使用情况，在 5 到 10 之间设置就足够了。
  在移动设备和平板电脑设备上，默认值为 5，以提高渲染性能。
  注意-更改质量设置可能是一项昂贵的操作（取决于浏览器和 GPU），因此在动画过程中不应更改此设置。">
              <NumberButton v-model="postprocessing.blur.quality" @change="changePostprocessing" :defaultValue="5" :wheelRatio="0.01" :moveRatio="0.05" step="1" :min="1" :max="50" name="质量"></NumberButton>
            </div>
            <div title="在线性 RGB 空间中进行模糊处理。
  这样可以产生更正确的混合/模糊颜色，但同时也会降低性能，因此默认情况下将其禁用。" class="custom-control custom-switch switch ppitem">
              <input type="checkbox" class="custom-control-input" id="blurLinearrgb" @change="changePostprocessing" v-model="postprocessing.blur.linearrgb">
              <label class="custom-control-label" for="blurLinearrgb">线性 RGB</label>
            </div>
            <div title="默认情况下，模糊图像时边缘的像素会重复。
  启用此设置后，像素将被镜像。
  这样可以稍微提高图像质量并减少边缘的伪影，但同时也要降低性能，因此默认情况下将其禁用。" class="custom-control custom-switch switch ppitem">
              <input type="checkbox" class="custom-control-input" id="blurMirroredges" @change="changePostprocessing" v-model="postprocessing.blur.mirroredges">
              <label class="custom-control-label" for="blurMirroredges">镜像边缘</label>
            </div>
          </template>
        </div>
        <div>
          <button class="btn btn-xs btn-default mr-2" @click="postprocessing=newPostprocessing();changePostprocessing()">重置</button>
        </div>
      </div>
      <div style="position:absolute;right:100%;top:calc(50% - 25px);height:50px;">
        <div class="postprocessingBtn text-shadow" :class="{hide:postprocessingHide}" @click="postprocessingHide=!postprocessingHide" style="opacity:0.6;padding:10px 5px"><i class="fas fa-2x fa-caret-right"></i></div>
      </div>
    </div>-->
</template>
<script>
  import NumberButton from '../../NumberButton'
  export default {
    components: {
      NumberButton
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        toolbar: null,
        postprocessingHide: false,
        postprocessing: this.newPostprocessing()
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {
      }
    },
    watch: {
      pano(val, oldval) {
        console.log('ppp pano change', val)
        if (val.postprocessing) {
          this.postprocessing = val.postprocessing
        } else {
          this.postprocessing = this.newPostprocessing()
        }
      }
    },
    computed: {
      position() {
        return this.config.position || ''
      },
      pano() {
        return this.publicData.pano || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
          //  changePostprocessing: {
          //    target: 'Data',
          //    name: 'formChage',
          //    arg: 'postprocessing',
          //  },
        },
        funcs: {
          doTarget: this.doTarget,
        }
      })
      if (this.pano.postprocessing) {
        this.postprocessing = this.pano.postprocessing
      }
    },
    destroyed() {
    },
    methods: {
      changePostprocessing() {
        this.$emit('changePostprocessing', this.postprocessing)
      },
      newPostprocessing() {
        return {
          light: {
            enabled: true,
            exposure: 0.0,
            lights: 0.0,
            shadows: 0.0,
            filterrange: 60.0,
            masking: 1.0,
            order: 1,
            phase: 1,
            quality: 3
          },
          sharpen: {
            enabled: true,
            strength: 0.0,
            range: 1.5,
            luminanceonly: true,
            order: 2,
            phase: 1,
            quality: 5
          },
          blur: {
            enabled: true,
            range: 0.0,
            linearrgb: false,
            mirroredges: true,
            order: 3,
            phase: 1,
            quality: 5
          }
        }
      },
    },
  }
</script>
<style scoped>
  label {
    color: white;
  }

  .baseArea {
    background-color: #0008;
    opacity: 1;
    display: block;
    padding: 5px;
  }

  .ppitem {
    margin: 5px 10px;
    font-size: 12px;
    display: flex;
    align-items: flex-start;
  }

    .ppitem label {
      margin-right: 5px;
      flex: none;
    }

    .ppitem .nb {
      flex: auto;
      max-width: 150px;
    }

  /*  .postprocessing {
    position: absolute;
    right: 0px;
    bottom: 0px;
    background-color: #fff6;
    font-size: 12px;
    padding: 5px;
    width: 140px;
    transition: all .5s;
  }

    .postprocessing.hide {
      right: -140px;
    }

  .postprocessingBtn {
    transform: rotate(0);
    transition: all .5s;
  }

    .postprocessingBtn.hide {
      transform: rotate(180deg);
    }*/
</style>
